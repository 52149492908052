import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { Form, Input, Button, notification } from "antd";

import { ContentBlock } from "../../components/Blocks";
import { TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { AuthContext } from "../../widgets";
import { removePreloader } from "../../helpers";

function RestorePassword({ form }) {
	const { getFieldDecorator, validateFields } = form;

	const [loading, setLoading] = useState(false);
	const { restorePassword } = useContext(AuthContext);

	function handleSubmit(event) {
		event.preventDefault();

		validateFields((errors, body) => {
			setLoading(true);

			if (!errors) {
				restorePassword(
					JSON.stringify(body),
					data => {
						// TODO: проверка ответов на промежуток
						if (+data.status === 200) {
							notification.success({
								message: "Данные о восстановлении пароля высланы на указанный E-mail/Телефон",
							});
							setLoading(false);
						}
						if (+data.status === 404) {
							notification.error({
								message: "Пользователь не найден",
							});
							setLoading(false);
						}
						if (+data.status === 400) {
							notification.error({
								message: "Некорректный логин",
							});
							setLoading(false);
						}
					},
					error => {
						console.error(error);
						notification.error({
							message: error,
						});
						setLoading(false);
					},
				);
			} else {
				notification.error({
					message: "Некорректные данные формы",
				});
				setLoading(false);
			}
		});
	}

	useEffect(() => {
		removePreloader();
	}, []);

	return (
		<Pages>
			<ContentBlock>
				<div className={"container"}>
					<TextBlock title={"Восстановить пароль"}>
						<Form layout={"vertical"} onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-12 col-md-6 col-xl-4">
									<Form.Item label={"Введите E-mail или телефон"}>
										{getFieldDecorator(
											"login",
											{
												rules: [
													{
														required: true,
														message: "Введите E-mail или телефон!",
													},
												],
											})(
											<Input />,
										)}
									</Form.Item>
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 col-xl-4">
									<Form.Item>
										<Button
											block
											type={"primary"}
											htmlType={"submit"}
											disabled={loading}
											loading={loading}
										>
                                            Восстановить пароль
										</Button>
									</Form.Item>
								</div>
							</div>
						</Form>
					</TextBlock>
				</div>
			</ContentBlock>
		</Pages>
	);
}

export default Form.create({ name: "restore-password" })(RestorePassword);

RestorePassword.propTypes = {
	form: PropTypes.object,
};
